<template>
	<div>
    <Loader v-if="!eventInfo || !eventInfo.id" />
    <div class="dashboard__container pt-3" v-if="eventInfo.id">
      <!-- <button @click="updateEventJobs2()">Update Ecents</button> -->
      <form ref="form" @submit.prevent>

      	<div class="dashboard__container--body mt-3" v-if="eventInfo">

          <div class="dashboard__container--body--col">
            <div>
            <h4>Attach Files</h4>

            <div class="mb-3">
              <label for="fileTitle">Details:</label>
              <input class="mb-2" placeholder="File Title" type="text" v-model.trim="fileTitle" id="fileTitle" />
              <textarea placeholder="File Description" name="fileDescription" id="fileDescription" cols="30" rows="2" v-model="fileDescription"></textarea>

              <input class="mt-3" type="file" ref="fileInputTip" accept="image/*,application/pdf,.doc" @change="previewImage">
              <progress :value="uploadValue" max="100" v-if="showBar"></progress>
              <div class="mb-3">
                <button v-if="imageData != null" class="btn btn__primary mt-3" @click="onUploadFile">
                  Upload
                  <transition name="fade">
                    <span class="ml-2" v-if="performingRequest3">
                    <i class="fa fa-spinner fa-spin"></i>
                    </span>
                  </transition>
                </button>
              </div>
              <div v-if="event.files && event.files.length >= 1">
                <vue-good-table
                  :columns="columns"
                  :rows="event.files"
                  >
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'url'">
                      <a :href="props.row.url" target="_blank"><i class="fas fa-external-link"></i></a>
                    </span>
                    <span v-else-if="props.column.field == 'extras'">
                      <button @click="deleteUploadedFile(props.row, props.index)">
                        <i class="fas fa-trash"></i>
                      </button>
                    </span>
                    <span v-else>
                      {{props.formattedRow[props.column.field]}}
                    </span>
                  </template>
                </vue-good-table>
              </div>
            </div>
            </div>
          </div>
          <div class="dashboard__container--body--col" v-if="event">

            <div class="mb-3">
              <h4>Additional Description (optional)</h4>
              <!-- <label for="eventDescription">Description:</label> -->
              <vue-editor id="eventDescription" v-model="event.description" required></vue-editor>
            </div>
          </div>


          <div class="dashboard__container--body--col" v-if="event.venue">
            <div>
            <h4>Attire</h4>
            <div class="mb-3">
              <vue-editor id="attire" v-model="event.venue.attire"></vue-editor>
            </div>
            </div>
          </div>
          <div class="dashboard__container--body--col" v-if="event.venue">
            <div>
            <div class="mb-3">
              <h4>Check-In Instructions</h4>
              <vue-editor id="checkin" v-model="event.venue.checkin"></vue-editor>
            </div>
          </div>

          </div>

          <div class="dashboard__container--body--col" v-if="event.venue">
            <div>
            <div class="mb-3">
              <h4>Parking Instructions</h4>
              <vue-editor id="parking" v-model="event.venue.parking"></vue-editor>
            </div>
          </div>
          </div>

          <div class="dashboard__container--body--col" v-if="event.venue">
            <div class="mb-3">
              <h4>Camping Instructions</h4>
              <vue-editor id="camping" v-model="event.venue.camping"></vue-editor>
            </div>
          </div>

          <div class="dashboard__container--body--col" v-if="event.venue">
            <div class="mb-3">
              <h4>Credentials Instructions</h4>
              <vue-editor id="creds" v-model="event.venue.creds"></vue-editor>
            </div>
          </div>

          <div class="dashboard__container--body--col" v-if="event.venue">
            <div class="mb-3">
              <h4>COVID Requirements:</h4>
              <vue-editor id="covid" v-model="event.venue.covid"></vue-editor>
            </div>
          </div>


          <div class="dashboard__container--body--col" v-if="event.venue">
            <div class="mb-3">
              <h4>Additional Notes:</h4>
              <vue-editor id="notes" v-model="event.venue.notes"></vue-editor>
            </div>
          </div>
        </div>
        <div class="dashboard__container--body">

          <div class="floating_buttons">
            <button class="btn btn__success btn__large" @click="updateEvent()" :disabled="event.status == 'cancelled'">
            Update Order
              <transition name="fade">
                <span class="ml-2" v-if="performingRequest">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
            </button>
          </div>
      	</div>
      </form>
    </div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import { VueEditor } from "vue2-editor";
import router from '@/router'
const fb = require('../../firebaseConfig.js')

export default {
  props: ['eventInfo'],
  name: 'eventInstructions',
  data: () => ({
    croppa: {},
    day:'',
    types: ['Contractors', 'Employees'],
    drugs: ['4-Panel', '6-Panel', '9-Panel'],
    backs: ['Basic', 'Premium', 'Platinum'],
    typess: ['Gig', 'Job'],
    statuses: ['Open', 'On-Hold', 'Closed'],
    showCancellation: false,
    job: '',
    multiDay: false,
    performingRequest: false,
    performingRequest2: false,
    performingRequest3: false,
    performingRequest4: false,
    imageData: null,
    fileTitle: '',
    fileDescription: '',
    uploadValue: 0,
    showBar:false,
    columns: [
      {
        label: 'Title',
        field: 'title',
      },
      {
        label: 'Description',
        field: 'description',
      },
      {
        label: 'Url',
        field: 'url',
        tdClass: 'text-right',
      },
      {
        label: '',
        field: 'extras',
        tdClass: 'text-right',
      },
    ]
  }),
  components: {
    VueEditor,
    Loader
  },
  computed: {
    ...mapState(['currentUser']),
    event() {
      return this.eventInfo
    },
  },
  methods: {
    previewImage(event) {
      this.uploadValue=0;
      this.imageData=event.target.files[0]
    },
    onUploadFile() {
      this.showBar = true
      let event = this.eventInfo
      let fileTitle = this.fileTitle
      let fileDescription = this.fileDescription
      let rand = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase()
      let storageRef = fb.storageRef.child('docs/' + rand).put(this.imageData);
      storageRef.on(`state_changed`, snapshot => {
        this.uploadValue=(snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error => {console.log(error.message)},
      () => {this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL)
            var docRef = fb.eventsCollection.doc(event.id)
            docRef.update({
              files: fb.firestore.FieldValue.arrayUnion({
                title: fileTitle,
                description: fileDescription,
                url: downloadURL
              })
            });
            event.files.push({
              title: fileTitle,
              description: fileDescription,
              url: downloadURL
            })
        })
        this.showBar = false
      })
      this.imageData = null
      this.fileTitle = ''
      this.fileDescription = ''
      this.$refs.fileInputTip.value=null
    },
    onFileTypeMismatch(file) {
      alert('Invalid file type. Please choose a jpeg or png file.')
    },
    onFileSizeExceed(file) {
      alert('Please choose a file smaller than 2MB')
    },
    
    updateEvent() {
      this.performingRequest = true
    	let event = this.event
      // let croppa = this.croppa
      let store = this.$store

        console.log(event)
        this.$store.dispatch('updateEvent', event)
        setTimeout(() => {
          // croppa.remove()
          this.performingRequest = false
          // let url = `/orders`
          // router.push(url)
        }, 2000)
    }
  },

  beforeDestroy () {
    this.croppa = null
    this.imageData = null
    this.showBar = null
    this.job = null
    this.multiDay = null
    this.fileTitle = null
    this.fileDescription = null
    this.performingRequest = null
    this.performingRequest2 = null
    this.performingRequest3 = null
    this.performingRequest4 = null
    this.day = null
    this.uploadValue = null
    this.columns = null
    delete this.imageData
    delete this.day
    delete this.job
    delete this.croppa
    delete this.multiDay
    delete this.fileTitle
    delete this.fileDescription
    delete this.performingRequest
    delete this.performingRequest2
    delete this.performingRequest3
    delete this.performingRequest4
    delete this.uploadValue
    delete this.showBar
    delete this.columns
    this.types = null
    delete this.types
    this.typess = null
    delete this.typess
    this.drugs = null
    delete this.drugs
    this.backs = null
    delete this.backs
    this.statuses = null
    delete this.statuses
    this.showCancellation = null
    delete this.showCancellation
    delete this.updateEvent
    delete this.activateEvent
    delete this.cancelEvent
    delete this.updateStaff
    delete this.updateVenue
    delete this.deleteEvent
    delete this.deleteUploadedFile
    delete this.deleteJob
    delete this.deleteDay
    delete this.addInitialDay
    delete this.addJob
    delete this.addDay
    delete this.onFileTypeMismatch
    delete this.goBack
    delete this.onFileSizeExceed
    delete this.onUploadFile
    delete this.previewImage
    delete this.venueSync
    delete this.removeGroup
    delete this.addGroup
    delete this.removeGroup
    

  },
  destroyed() {
    VueEditor
    console.log(this)
  }
}
</script>